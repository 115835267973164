a:hover{
 opacity: 0.5;
 color: rgb(89, 103, 0);
}

.appstoreLink {
    text-decoration: underline;
    margin-left: 40px;
   
}

.chunkLabel {
    text-decoration: none;
}

body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: rgb(80, 80, 80);
    line-height: 1.5;
  }

  .header {
    display: flex;
    justify-content:space-evenly;
    /* align-items: center; */
    height: 100%;
  }
  h1{
    font-size: 2em;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em 0;
    font-weight: 400;
    line-height: 1.2;
  }

  .title {
    margin-left: 50px;
  }
  
  .h2{
    text-decoration: none;
    font-weight: normal;
  }