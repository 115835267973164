.notfound {
    /* position: fixed; */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 3rem;
}

.story {
    padding-top: 2rem;
    line-height: 1.8;
}