nav {
    background-color: #fff;
    color: #333;
    display: block;
    justify-content: space-between;
    border-bottom: 1px solid rgb(89, 103, 0, 0.1);;
    font-weight: 300;
    /* padding: 0 1em; */
    padding-left: 1.5rem;
    padding-top: 1rem;
    
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    margin: 0;
   
  }
  
  /* ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  } */
  
  li {
    margin-right: 1rem;
    padding-bottom: 0.5rem;
  }
  
  a {
    color: #333;
    /* text-decoration: none; */
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li:not(:last-child) {
    margin-right: 20px;
  }
  
  .nav-links a {
    position: relative;
    text-decoration: none;
    color: #000;
  }
  
  .nav-links a::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #000;
    transition: width 0.2s ease;
  }
  
  /* .nav-links a:hover::after,
  .nav-links a.active::after {
    width: 100%;
  } */

  .active {
    border-bottom: 2px solid rgba(63, 63, 63);
  }