.container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .container {
      max-width: 960px;
      margin: 0 auto;
    }
  }
  
  